<template>
  <div class="company">
    <h2 class="company__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900">
      {{ $t('account.company.title') }}
    </h2>

    <form
      class="company__form tw-w-full"
      :disabled="isDisabled"
      @submit.prevent="submitted"
    >
      <ValidationObserver
        ref="observer"
        data-test="observer"
      >
        <template>
          <ValidationProvider
            ref="company-name-provider"
            :name="$t('app.fields.company_name')"
            rules="required|max:255"
            data-test="provider"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-text
                id="company_name"
                v-model="formData.company_name"
                :label="$t('app.labels.company_name')"
                :disabled="isDisabled"
                :error="invalid && validated"
                :hint="errors[0]"
                :loader="$wait.is('fetching company')"
                data-test="company-name-field"
                type="text"
                name="company_name"
                class="tw-mb-3"
                autocomplete="organization"
                required
              />
            </template>
          </ValidationProvider>

          <!-- Address -->
          <fieldset
            class="company__form__address pt-2"
          >
            <legend class="tw-hidden">
              {{ $t('account.company.titles.address') }}
            </legend>
            <ValidationProvider
              :name="$t('app.fields.street_name')"
              rules="required|max:255"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="street_name"
                  v-model="formData.address.street_name"
                  :label="$t('app.labels.street_name')"
                  :disabled="isDisabled"
                  :error="!$wait.is('fetching company') && invalid && validated"
                  :hint="errors[0]"
                  :loader="$wait.is('fetching company')"
                  data-test="street-field"
                  type="text"
                  name="street_name"
                  class="tw-mb-2"
                  autocomplete="street-address"
                  required
                />
              </template>
            </ValidationProvider>

            <div class="company__form__address__aside tw-flex tw-flex-col tw-mb-2 2sm:tw-flex-row">
              <ValidationProvider
                :name="$t('app.fields.postal_code')"
                rules="required|max:255"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="postal_code"
                    v-model="formData.address.postal_code"
                    :label="$t('app.labels.postal_code')"
                    :disabled="isDisabled"
                    :error="!$wait.is('fetching company') && invalid && validated"
                    :hint="errors[0]"
                    :data-vv-as="$t('app.fields.postal_code')"
                    :loader="$wait.is('fetching company')"
                    data-test="postal-code-field"
                    class="tw-w-full 2sm:tw-w-1/3 2sm:tw-mr-2 tw-mb-2 2sm:tw-mb-0"
                    type="text"
                    name="postal_code"
                    autocomplete="postal-code"
                    required
                  />
                </template>
              </ValidationProvider>
              <ValidationProvider
                :name="$t('app.fields.city')"
                rules="required|max:255"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="city"
                    v-model="formData.address.city"
                    :label="$t('app.labels.city')"
                    :disabled="isDisabled"
                    :error="!$wait.is('fetching company') && invalid && validated"
                    :hint="errors[0]"
                    :data-vv-as="$t('app.fields.city')"
                    :loader="$wait.is('fetching company')"
                    data-test="city-field"
                    class="tw-w-full 2sm:tw-w-1/3 tw-mb-2 2sm:tw-mb-0 2sm:tw-mr-2"
                    type="text"
                    name="city"
                    autocomplete="address-level2"
                    required
                  />
                </template>
              </ValidationProvider>

              <ValidationProvider
                :name="$t('app.fields.country')"
                rules="required|max:2"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <country-selector
                    id="country"
                    v-model="formData.address.country"
                    :label="$t('app.labels.country')"
                    :error="!$wait.is('fetching company') && invalid && validated"
                    :hint="errors[0]"
                    :disabled="isDisabled"
                    :items="countries"
                    :data-vv-as="$t('app.fields.country')"
                    :loader="$wait.is('fetching company')"
                    :preferred-countries="true"
                    data-test="country-field"
                    color="#287696"
                    has-name
                    type="text"
                    name="country"
                    autocomplete="country"
                    class="2sm:tw-w-1/3"
                    required
                  />
                </template>
              </ValidationProvider>
            </div>
          </fieldset>

          <p
            v-if="!isUserShipper"
            class="company__form__address-explanation tw-italic tw-leading-tight"
            data-test="address-explanation"
          >
            {{ $t('account.company.paragraphs.address') }}
          </p>

          <div
            v-if="isUserShipper"
            class="company__form__buttons tw-flex tw-justify-between tw-mt-10"
          >
            <ui-button
              :disabled="hasPendingRequest"
              variant="link"
              type="button"
              data-test="cancel-button"
              @click="reset"
            >
              {{ $t('account.buttons.cancel') }}
            </ui-button>
            <ui-button
              :loading="$wait.is('updating company')"
              :disabled="hasPendingRequest"
              class="company__form__buttons__save tw-mr-2"
              variant="primary"
              type="submit"
              data-test="save-button"
            >
              {{ $t('account.buttons.save') }}
            </ui-button>
          </div>
        </template>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'
  import { showToaster } from '@/services/Toaster'
  import { Company } from '@/resources'
  import usePhoneCodeCountries from '@/composables/usePhoneCodeCountries'

  import CountrySelector from '@/components/CtkPhoneNumber/_subs/CountrySelector'
  import CtkInputText from '@/components/CtkInputs/CtkInputText'

  /**
   * @module view - company
   */
  export default {
    name: 'Company',
    components: {
      CtkInputText,
      CountrySelector
    },
    metaInfo () {
      return {
        title: this.$t('account.company.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)

      next()
    },
    data () {
      return {
        address: {},
        formData: {
          company_name: null,
          address: {
            street_name: null,
            postal_code: null,
            city: null,
            country: null
          }
        }
      }
    },
    mounted () {
      this.reset()

      /**
       * Fetch company informations
       * (do ignore if we're in a test environment; causing http issues
       * during the unit tests)
       */
      if (process.env.NODE_ENV !== 'test') {
        this.fetchCompany()
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      ...mapGetters([
        'isUserShipper'
      ]),
      countries () {
        const { countries } = usePhoneCodeCountries()
        return countries
      },
      /**
       * Returns true if there are any pending requests
       * @function hasPendingRequest
       * @returns {boolean}
       */
      hasPendingRequest () {
        return this.$wait.is('updating company') || this.$wait.is('fetching company')
      },
      isDisabled () {
        return !this.isUserShipper || this.hasPendingRequest
      }
    },
    methods: {
      ...mapActions('auth', [
        'setUserInfos',
        'retrieveCompany'
      ]),
      /**
       * Fetch the company informations
       * @function fetchCompany
       */
      fetchCompany () {
        this.$wait.start('fetching company')
        this.retrieveCompany()
          .then(({ data }) => {
            const { address } = data
            this.formData.company_name = data.name
            if (address) {
              this.address = {
                street_name: address.street_name,
                city: address.city,
                postal_code: address.postal_code,
                country: address.country
              }
              this.formData.address = Object.assign({}, this.address)
            }
          })
          .catch(() => {})
          .finally(() => {
            this.$wait.end('fetching company')
          })
      },
      submitted () {
        if (this.$wait.is('updating company')) {
          return
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) {
              return
            }

            /**
             * Update the company name
             */
            const nameRequest = Company.name({
              cid: this.getUserInfos.companies[0].uuid
            }, {
              name: this.formData.company_name
            })

            /**
             * Update the company address
             */
            const { address } = this.formData
            const addressRequest = Company.address({
              cid: this.getUserInfos.companies[0].uuid
            }, {
              street: address.street_name,
              postal_code: address.postal_code.toString(),
              city: address.city,
              country_code: address.country
            })

            this.$wait.start('updating company')
            Promise.all([
              nameRequest,
              addressRequest
            ])
              .then(() => {
                this.setUserInfos({
                  ...this.getUserInfos,
                  companies: [
                    {
                      ...this.getUserInfos.companies[0],
                      name: this.formData.company_name
                    }
                  ]
                })
                showToaster(
                  this,
                  this.$t('account.company.paragraphs.updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch((err) => {
                if (!err.response) return

                /**
                 * TODO: Handle missing violations?
                 */
                showToaster(
                  this,
                  this.$t('account.paragraphs.generic_error'), {
                    type: 'error',
                    position: 'bottom-right'
                  }
                )
              })
              .finally(() => this.$wait.end('updating company'))
          })
      },
      /**
       * Set the fields information according to the current user informations
       * @function reset
       */
      reset () {
        const {
          companies
        } = this.getUserInfos

        this.formData = {
          company_name: companies[0].name,
          address: Object.assign({
            street_name: null,
            city: null,
            postal_code: null,
            country: null
          }, this.address)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .company {
    &__title {
      @media only screen and (max-width: 857px) {
        display: none;
      }
    }
  }

</style>
